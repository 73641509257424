<template>
  <lightning-template
    :wishes-lightening="wishesLightening"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import LightningTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-pigmentation/lightning/LightningTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('wishesLightening')];

export default {
  name: 'Lightning',
  components: { LightningTemplate },
  mixins: [makeStep(FIELDS)],
  watch: {
    wishesLightening() {
      this.showNextStep();
    }
  }
};
</script>
